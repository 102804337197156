<template>
    <v-card flat>
        <v-card-title>
            <span class="secondary--text font-weight-bold">SSH keys</span>
        </v-card-title>
        <v-card-text>
            <v-skeleton-loader v-if="fetchingSSHKeys" class="mx-auto" type="table"></v-skeleton-loader>
            <template v-else>
                <div class="d-flex align-center">
                    <v-btn @click="generateSSHKey()" :loading="generatingKey" class="mr-2" color="primary">
                        <v-icon left>add</v-icon>
                        <span class="font-weight-bold">Generate SSH key</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field
                        label="Search for a SSH key..."
                        autofocus
                        v-model="search"
                        hide-details
                        prepend-inner-icon="mdi-filter"
                        solo
                        flat
                        background-color="grey lighten-4"
                        dense
                        clearable
                        class="mr-2"></v-text-field>
                    <v-btn :loading="fetchingSSHKeys" @click="$store.dispatch('userStore/fetchSSHKeys')" icon><v-icon>refresh</v-icon></v-btn>
                </div>
                <v-data-table
                    :items="sshKeys"
                    item-key="tknid"
                    :search="search"
                    :headers="headers"
                    :options="tableOptions"
                    :footer-props="{ 'items-per-page-options': itemsPerPageOptions }">
                    <template v-slot:top="{ pagination, options, updateOptions }">
                        <v-data-footer
                            :pagination="pagination"
                            :options="options"
                            @update:options="updateOptions"
                            :itemsPerPageOptions="itemsPerPageOptions"
                            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                            class="no-border" />
                    </template>
                    <template v-slot:no-data>
                        <span>No SSH keys have been added yet. Click generate SSH key to create a new one.</span>
                    </template>
                    <template v-slot:[`item.ssh_key`]="{ item }">
                        <code class="text-body-1 d-block text-truncate" :style="`width: ${cellWidth()}`">{{ item.ssh_key }}</code>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <copy-to-clipboard :textToCopy="item.ssh_key" buttonClass="caption font-weight-bold" :iconButton="true"></copy-to-clipboard>
                        <SSHDeleteDialog
                            :token="item.ssh_key"
                            dialogTitle="SSH Key Delete"
                            :apiURL="`/tokens/ssh_keys/${item.tknid}`"
                            :fetchTokensDispatch="
                                () => {
                                    $store.dispatch('userStore/fetchSSHKeys')
                                }
                            "></SSHDeleteDialog>
                    </template>
                </v-data-table>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
const SSHDeleteDialog = () => import('@/components/DeleteTokenDialog.vue')
const CopyToClipboard = () => import('@/components/CopyToClipboard')

export default {
    data() {
        return {
            token: '',
            generatingKey: false,
            search: '',
            itemsPerPageOptions: [25, 50, 100, -1],
            tableOptions: {
                page: 0,
                itemsPerPage: 25,
                pageStart: 1,
                pageStop: 25,
                itemsLength: 25
            },
            headers: [
                { text: 'Key', align: 'left', value: 'ssh_key' },
                { text: 'Actions', align: 'right', value: 'actions' }
            ]
        }
    },
    components: { SSHDeleteDialog, CopyToClipboard },
    methods: {
        cellWidth() {
            return `${window.innerWidth - 300}px`
        },
        generateSSHKey: function () {
            this.generatingKey = true
            this.$axios
                .post('/tokens/ssh_keys')
                .then(response => {
                    this.$store.dispatch('userStore/fetchSSHKeys')
                })
                .finally(() => {
                    this.generatingKey = false
                })
        },
        deleteToken: function (tknid) {
            this.$axios
                .delete(`/tokens/ssh_keys/${tknid}`)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Token deleted successfully!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch('userStore/fetchSSHKeys')
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Could not delete token.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                })
        },
        openDialog: function (token) {
            this.token = token
        }
    },
    computed: {
        ...mapState('userStore', ['sshKeys', 'fetchingSSHKeys'])
    },
    mounted() {
        this.$store.dispatch('userStore/fetchSSHKeys')
    }
}
</script>
